import React from 'react';

const UserDetailsPopup = ({ user, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-3/4 max-w-lg">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          Close
        </button>
        <h2 className="text-xl font-bold mb-4">User Details</h2>
        <p><strong>Employee ID:</strong> {user.emp_id}</p>
        <p><strong>Full Name:</strong> {user.first_name} {user.last_name}</p>
        <p><strong>Phone Number:</strong> {user.phone_no}</p>
        <p><strong>Email:</strong> {user.email}</p>
        <p><strong>Department:</strong> {user.sub_dept_name}</p>
        <p><strong>Location:</strong> {user.locality}</p>
        <p><strong>Status:</strong> {user.user_status}</p>
        <p><strong>Designation:</strong> {user.designation}</p>
        <p><strong>Manager:</strong> {user.manager_name}</p>
        <p><strong>Gender:</strong> {user.gender}</p>
      </div>
    </div>
  );
};

export default UserDetailsPopup;
